<template>
  <router-link :to="navigateTo" class="contact-item">
    <woot-thumbnail :src="contactThumbnail" :username="name" size="24px" />
    <div class="contact-details">
      <h5 class="text-block-title name text-slate-800 dark:text-slate-200">
        {{ name }}
      </h5>
      <p class="details-meta">
        <span
          v-if="contactCompany"
          class="contactCompany text-slate-800 dark:text-slate-200"
        >
          • {{ contactCompany }}
        </span>
        <span
          v-if="phone && isAdmin"
          class="phone text-slate-800 dark:text-slate-200"
        >
          • {{ phone }}
        </span>
        <span
          v-if="email && isAdmin"
          class="email text-slate-800 dark:text-slate-200"
        >
          • {{ email }}
        </span>
      </p>
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';
import { frontendURL } from 'dashboard/helper/URLHelper';
import isAdmin from '../../../mixins/isAdmin';
export default {
  mixins: [isAdmin],
  props: {
    id: {
      type: [String, Number],
      default: 0,
    },
    email: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    thumbnail: {
      type: String,
      default: '',
    },
    accountId: {
      type: [String, Number],
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      currentContact: 'contacts/getContact',
    }),
    navigateTo() {
      return frontendURL(`accounts/${this.accountId}/contacts/${this.id}`);
    },
    contactThumbnail() {
      return this.currentContact(this.id).thumbnail;
    },
    contactCompany() {
      return this.currentContact(this.id).additional_attributes.company_name;
    },
  },
  mounted() {
    this.fetchContact(this.id);
  },
  methods: {
    fetchContact(id) {
      this.$store.dispatch('contacts/show', { id });
    },
  },
};
</script>

<style scoped lang="scss">
.contact-item {
  @apply cursor-pointer flex items-center p-2 rounded-sm hover:bg-slate-25 dark:hover:bg-slate-800;
}
.contact-details {
  @apply ml-2 rtl:mr-2 rtl:ml-0;
}
.name {
  @apply m-0;
}
.details-meta {
  @apply m-0 text-slate-600 dark:text-slate-200 text-sm flex items-center;

  span {
    @apply ml-1 rtl:mr-1 rtl:ml-0;
  }
}
</style>
